@import "../../styles/base";

.footer {
  &::after {
    content: "";
    display: block;
    height: 40px;
    width: 100%;
    background: linear-gradient(
      0deg,
      rgb(0, 94, 199) 0px,
      rgb(0, 94, 199) 25%,
      rgb(5, 116, 240)
    );
  }

  padding-bottom: 120px; // Make room for the next/back and cart pinned to bottom

  &::after {
    height: 0;
  }

  @include fromTablet {
    padding-bottom: 0;
    &::after {
      height: 40px;
    }
  }
}

.contact {
  @include container;

  max-width: $max-width;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 1.6rem;

  @include fromTablet {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  a {
    font-weight: bold;
    text-decoration: none;
  }

  span {
    display: block;

    @include fromTablet {
      display: inline;
    }
  }
}

.nav {
  @include container;

  font-size: $font-s;
  padding-top: 20px;
  padding-bottom: 20px;

  @include fromTablet {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    gap: 70px;

    div:first-child {
      flex: 0 0 260px;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-top: 5px;
    margin-right: 16px;
    display: block;

    @include fromTablet {
      display: inline-block;
      margin-top: 10px;
    }
  }

  a {
    font-weight: bold;
    text-decoration: none;
    position: relative;
    padding-right: 10px;
    display: inline-block;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 7px;
      height: 4px;
      width: 4px;
      border: 1px solid $blue;
      border-width: 1px 1px 0 0;
      transform: rotate(45deg);
    }
  }
}
