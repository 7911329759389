@import '../../styles/base';

.react-tabs {
  width: auto;
  min-height: 60vh;
}

.react-tabs__tab-list { 
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  position: relative;
  padding: 6px 10px 0px 10px;
  cursor: pointer;

  &:first-child {
    margin-left: -10px;
  }

  a {
    font-weight: bold;
  }
}

.react-tabs__tab--selected {

  &::after {
    content: '';
    position: absolute;
    height: 4px;
    left: 12px;
    right: 12px;
    bottom: -5px;
    background: $blue;
  }

  a {
    color: $black;
  }
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.popup-content .react-tabs__tab-panel--selected {
  width: 100%;
  max-height: calc(80vh - 70px - 83px);
  overflow-y: auto;
}