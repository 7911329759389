@import "../../../../styles/base";

.phone {
  @include hideOnMobile;

  text-align: right;

  padding-right: 20px;
  border-right: 1px solid #fff;
  line-height: 1;
}

.number {
  font-size: 2rem;
  font-weight: 900;
  color: #fff;

  &:link ,
  &:visited {
    color: #fff;
    text-decoration: none;
  }
}

.openingHours {
  font-size: 1.25rem;
  margin-bottom: 5px;

  b {
    color: $orange;
  }

  a {
    &:link ,
    &:visited {
      color: $orange;
      text-decoration: none;
    }
  }
}
