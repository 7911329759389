@import '../../../styles/base';

.topBar {
  @include gradient;

  position: relative;
  color: white;
  padding: 10px 0;

  img {
    @include untilDesktop {
      height: 50px !important;
      width: auto !important;
    }
  }
  a{
    text-decoration: none;
  }
}

.skipLink {
  color: #fff !important;
  position: absolute;
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  
  &:focus {
    top: 0;
    left: 160px;
    width: auto;
    height: auto;
  }
}

  .popup {
    position: absolute;
    border-radius: 4px;
    padding: 20px;
    left: 0;
    top: 100%;
    margin-top: 20px;
    background: $blue;
    @include boxShadow;

    @include untilDesktop {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 290px;
      z-index: 2;
    }
    
    &::before {
      content: '';
      display: block;
      position: absolute;
      background: $blue;
      transform: rotate(45deg);
      height: 15px;
      width: 15px;
      top: -7px;
      left: 65px;

      @include untilDesktop {
        display: none;
      }
    }
  }

  .overlay {
    @include untilDesktop {
      position: fixed;
      width: 100%;
      height: 100%;
      visibility: visible;
      top: 0;
      left: 0;
      z-index: 0;
      background-color: rgba(0,0,0,.8);
    }
  }

    .popupBtns {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
    
    .popupTitle {
      flex: 0 0 100%;
      font-size: $font-m;
      margin-top: 0;
      font-weight: bold;
    }

      .btn {
        @include btn;

        color: $white !important;
        text-decoration: none;
        border: 1px solid $white;

        @include untilDesktop {
          flex: 0 0 100%;
        }
      }

      .btnAlt {
        @include btn;

        background: $white !important;
        color: $blue !important;
        @include untilDesktop {
          flex: 0 0 100%;
        }
      }

.inner {
  @include container;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
