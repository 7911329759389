// These types override the ones in 'react-calendar/dist/Calendar.css'.
// More styling info here: https://github.com/wojtekmaj/react-calendar
@import "base";

body .react-calendar {
  width: 100%;
  margin-bottom: 10px;
}

// Setting the React-Calendar min/max-detail properties to "month"
// prevents the user from clicking the month to see the year
// (which is what we want) but then also makes the month name
// hidden (which we don't want). So this is to make the month name
// visible again.
body .react-calendar__navigation .react-calendar__navigation__label:disabled {
  visibility: visible;
  background-color: white;
}

// for month change animation
body .react-calendar__viewContainer {
  overflow: hidden;
  gap: 1rem;
  padding: 1px;
}

body .react-calendar--doubleView .react-calendar__viewContainer .react-calendar__month-view {
  flex: 0 0 calc(50% - 0.5rem);
  margin: 0;
}

body .react-calendar.calendarCustom {
  .react-calendar__viewContainer {
    margin: 1.5rem 2rem;

    @include untilTablet {
      margin: 0 1.5rem;
    }
  }

  // month title container
  .react-calendar__navigation {
    justify-content: space-between;
    align-items: center;
  }

  // hide double arrows
  .react-calendar__navigation__label__divider,
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  // month titles
  .react-calendar__navigation__label {
    font-size: 1.6rem;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    .react-calendar__navigation__label__labelText {
      margin: 0 auto;
    }

    @include untilTablet {
      flex-direction: column;
      gap: 5px;
      align-items: center;
    }
  }

  // Vertical double calendar view
  @include untilTablet {
    .react-calendar__viewContainer {
      flex-direction: column;
      gap: 0;

      .react-calendar__month-view {
        width: 100%;
      }
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 1.2rem 1.6rem;

    abbr {
      text-decoration: none;
      font-size: 1rem;
      letter-spacing: 0.5px;
    }

    @include untilTablet {
      padding: 1rem;
    }
  }

  .react-calendar__month-view__days {
    border: none;
    box-shadow: none;

    // matching weekday disabled colour to weekend disabled
    .react-calendar__month-view__days__day--weekend:disabled {
      abbr {
        color: #000000;
        opacity: 30%;
      }
    }
  }
}
