
@import '../../../../styles/base';

.login {
    text-transform: uppercase;
    font-size: 1.2rem;
    padding-left: 20px;
    position: relative;

    a {
        text-transform: none;
        font-size: 1.6rem;
        display: block;       
        color: #fff;
        font-weight: 900;
        line-height: 1;
        margin-top: 3px;
    }
}

.loginMenu {
    position: absolute;
    right: 0;
    list-style: none;
    background: #fff;
    margin: 10px 0 0;
    padding: 5px;
    @include boxShadow;

    a {
        color: $blue;
        text-decoration: none;
        padding: 5px 8px;
        display: block;
        margin: 0;
    }

    li + li {
        // margin-top: -5px;
    }
}