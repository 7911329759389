@import "base";

.checkoutdotcom-frames {

  display: flow-root;

  > div {
    border: solid silver 1px;
    font-size: $font-m;
    border: 1px solid $grey-dark;
    border-radius: 4px;
    padding: 7px;
    width: 260px;
    height: 40px;
    margin-bottom: 15px;

    &.frame--focus {
      border-color: $blue;
    }
    &.frame--invalid {
      border-color: red;
    }
  }
}
