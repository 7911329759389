@font-face {
	font-family: 'Avenir Next';
	src: url('da92a44f-8e1d-4248-ba7b-b12c91a118a9.woff2') format('woff2'),
		url('b679252b-1f50-49c3-9b2e-002bb8827c2e.woff') format('woff');
	font-weight: 250;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('0930f14e-7d95-4e17-8323-80b9a8a6a8c8.woff2') format('woff2'),
		url('27711a40-7975-4707-a301-552acc0c79ad.woff') format('woff');
	font-weight: 250;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('f64c3634-1027-4aec-8bd4-d96f2444f559.woff2') format('woff2'),
		url('91f23395-35b5-470e-9680-45d860e84f5b.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('9d625b17-c3f0-46d8-88cf-8fc52fed6638.woff2') format('woff2'),
		url('22e2f1bc-c893-4f6f-8f06-0ff76ae7b041.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('9544095b-fbf6-4d50-870f-a5102776eae4.woff2') format('woff2'),
		url('7c22a3c2-6fb4-4dbf-9112-53b06942662a.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('82eb5ca5-8702-4bb5-ac8d-6c33995824fb.woff2') format('woff2'),
		url('f61d18ba-8ac6-49d7-8581-4ff520a9fe06.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('c2abd1c4-1332-43d3-a7d0-c24c359d2bb8.woff2') format('woff2'),
		url('888f1b57-0692-499d-91f0-718136703ef4.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('ec5598d7-82d9-449f-a448-f608720cd609.woff2') format('woff2'),
		url('cd2cf773-80b7-421c-88c9-78c9f9cb3d75.woff') format('woff');
	font-weight: 900;
	font-style: italic;
}